/** @jsx jsx */
import { navigate } from 'gatsby'
import { Text, Flex, jsx, Grid, Box } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { Fragment, useState, useRef, useCallback, useEffect } from 'react'
import Div100vh from 'react-div-100vh'

import { generateRandomName } from '@utils/generateRandomName'
import { SEO } from '@components/SEO'
import { Info } from '@components/Info'
import { DrawkitIcon, InvisiIcon, ChromaIcon, InkIcon } from '@components/Icons'

const SwapText = ({ first, second }: { first: string; second: string }) => {
  const [switched, setSwitched] = useState(false)
  const timer = useRef()

  const switchText = useCallback(() => {
    setSwitched((s) => !s)
  }, [setSwitched])

  useEffect(() => {
    timer.current = setInterval(switchText, 3600)
    return () => {
      clearTimeout(timer.current)
    }
  })

  return <Text sx={{ lineHeight: 1.15 }}>{switched ? second : first}</Text>
}

const BGVideo = ({ sources: { image, vImage, video, vVideo } }) => {
  const videoRef = useRef<HTMLVideoElement>()
  const breakpoint = useBreakpointIndex()

  if (typeof window === 'undefined') {
    return null
  }

  const videoPlay = useCallback(async () => {
    if (!videoRef.current) return
    try {
      await videoRef.current.play()
    } catch (e) {
      console.log('failed autoplay', e)
      videoRef.current.poster = breakpoint > 0 ? image : vImage
    }
  }, [breakpoint])

  useEffect(() => {
    videoPlay()
  }, [breakpoint])

  return breakpoint > 0 ? (
    <video
      ref={videoRef}
      key="v-video"
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center'
      }}
      playsInline
      // autoPlay
      muted
      loop
    >
      <source src={vVideo} type="video/mp4" />
    </video>
  ) : (
    <video
      ref={videoRef}
      key="h-video"
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center'
      }}
      playsInline
      // autoPlay
      muted
      loop
    >
      <source src={video} type="video/mp4" />
    </video>
  )
}

const AppLink = ({ title, url, video, vVideo, image, vImage, Icon }) => {
  const handleClick = useCallback(() => {
    const randomName = generateRandomName()
    navigate(`${url}/${randomName}`, { state: { action: 'create' } })
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        background: 'black',
        overflow: 'hidden'
      }}
      onClick={handleClick}
    >
      <BGVideo sources={{ vImage, image, vVideo, video }} />

      <Flex
        sx={{
          position: 'absolute',
          bottom: '0.5em',
          left: 0,
          color: 'white',
          mixBlendMode: 'difference',
          zIndex: 9999,
          fontSize: '1.3em'
        }}
      >
        <Icon
          sx={{
            height: '1.75em',
            fill: 'white',
            pt: '0.1em',
            ml: '-0.1em',
            mr: '-0.03em'
          }}
        />
        <Text sx={{ lineHeight: 1.15 }}>{title}</Text>
      </Flex>
    </Box>
  )
}

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false)

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  return (
    <Fragment>
      <SEO />

      <Div100vh>
        <Flex
          sx={{
            position: 'absolute',
            top: '0.15em',
            left: 0,
            color: 'white',
            mixBlendMode: 'difference',
            zIndex: 9999,
            fontSize: '1.3em'
          }}
        >
          <DrawkitIcon
            sx={{
              height: '1.75em',
              fill: 'white',
              pt: '0.1em',
              ml: '-0.1em',
              mr: '-0.03em'
            }}
          />
          <SwapText first="DrawKit 1" second="FrameShare" />
        </Flex>
        <Flex
          as="button"
          onClick={handleOpen}
          sx={{
            position: 'absolute',
            top: '0.15em',
            right: '0.15em',
            color: 'white',
            mixBlendMode: 'difference',
            zIndex: 9999,
            appearance: 'none',
            bg: 'transparent',
            border: 'none',
            fontSize: '1.3em',
            fontFamily: 'heading',
            lineHeight: 1.15,
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          <Text>Info</Text>
        </Flex>
        <Grid
          sx={{
            height: '100%',
            width: '100%',
            gridTemplateColumns: ['100%', '1fr 1fr 1fr'],
            gridTemplateRows: ['1fr 1fr 1fr', '100%'],
            cursor: 'pointer'
          }}
          gap={0}
        >
          <AppLink
            title="InkSpill"
            url="/speed"
            image={require('@media/ink-h.jpg').default}
            vImage={require('@media/ink-v.jpg').default}
            video={require('@media/inkspill-s.mp4').default}
            vVideo={require('@media/inkspillV-s.mp4').default}
            Icon={InkIcon}
          />
          <AppLink
            title="ChromaDual"
            url="/multi"
            image={require('@media/chroma-h.jpg').default}
            vImage={require('@media/chroma-v.jpg').default}
            video={require('@media/chroma-s.mp4').default}
            vVideo={require('@media/chromaV-s.mp4').default}
            Icon={ChromaIcon}
          />
          <AppLink
            title="InvisiPaint"
            url="/blind"
            image={require('@media/invisi-h.jpg').default}
            vImage={require('@media/invisi-v.jpg').default}
            video={require('@media/invisi-s.mp4').default}
            vVideo={require('@media/invisiV-s.mp4').default}
            Icon={InvisiIcon}
          />
        </Grid>

        <Info show={showModal} handleClose={handleClose} />
      </Div100vh>
    </Fragment>
  )
}

export default IndexPage
