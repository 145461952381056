// import HRID from '@utils/hrid'

export const generateRandomName = (() => {
  const randomNumber = new Uint8Array(3)

  return () => {
    window.crypto.getRandomValues(randomNumber)
    return randomNumber.join('').slice(0, 6)
  }
})()
