/** @jsx jsx */
import { Text, Box, jsx, Flex, Link } from 'theme-ui'
import { useCallback } from 'react'
import { navigate } from 'gatsby'

import { generateRandomName } from '@utils/generateRandomName'
import { DrawkitIcon, InvisiIcon, ChromaIcon, InkIcon } from '@components/Icons'

const InfoText = (props) => (
  <Text
    as="div"
    sx={{
      ml: '2em',
      mt: '-0.1em',
      lineHeight: 1.1,
      mb: '2.5em',
      pr: '0.5em'
    }}
    {...props}
  />
)

const Title = ({ children, Icon, hideIcon }) => {
  return (
    <Flex
      sx={{
        color: 'white',
        mixBlendMode: 'difference',
        fontSize: '1.3em',
        pt: '0.15em'
      }}
    >
      <Icon
        sx={{
          height: '1.75em',
          fill: 'white',
          pt: '0.1em',
          ml: '-0.1em',
          mr: '-0.03em',
          display: hideIcon ? 'none' : 'block'
        }}
      />
      <Text sx={{ lineHeight: 1.15 }}>{children}</Text>
    </Flex>
  )
}

export const Info = ({ show, handleClose }) => {
  const handleClick = useCallback(() => {
    const randomName = generateRandomName()
    navigate(`/multi2/${randomName}`, { state: { action: 'create' } })
  }, [])

  return (
    show && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          color: 'black',
          zIndex: 29999,
          bg: 'white'
        }}
      >
        <Box
          sx={{
            maxWidth: '29em',
            bg: 'white'
          }}
        >
          <Title Icon={DrawkitIcon}>DrawKit 1</Title>
          <InfoText>
            Designed by art therapists, art educators, and creative coders,
            FrameShare revolutionizes digital art making through a visceral,
            messy, innovative workspace that is collaborative in realtime.
            DrawKit 1 is the first software package developed by FrameShare. It
            includes three unique and innovative drawing frameworks:
          </InfoText>

          <Title Icon={InkIcon}>InkSpill</Title>
          <InfoText>
            Much like an ink brush on paper, your mark will bleed onto the
            virtual canvas if you become hesitant or cautious in your strokes.
            By encouraging users to sketch fast and freely, the InkSpill
            simulates gesture drawing techniques used by professional drafters
            and painters.
          </InfoText>

          <Title Icon={ChromaIcon}>ChromaDual</Title>
          <InfoText>
            With this tool, two artists collaboratively draw on a shared,
            virtual canvas that is initially split down the middle by two
            colors. Each artists wields one of the two colors, and can draw on
            top of the other artist’s color. Creations necessitate teamwork as
            the artists give and take canvas space.
            <br />
            <br />
            In the following link you can also draw with an alternative version
            of ChromaDual wherein the brush size scales proportionally to the
            presence of your color on the canvas:
            <br />
            <Link
              onClick={handleClick}
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                },
                textDecoration: 'underline'
              }}
            >
              ChromaDual with scaling brush
            </Link>
          </InfoText>

          <Title Icon={InvisiIcon}>InvisiPaint</Title>
          <InfoText>
            Artists can use multiple colors and brush sizes on a single
            composition. Here’s the catch: Artist can’t see what they’re drawing
            until they hit the “finished” button. This simulates blind contour
            exercises which helps artists move forward when they are held back
            by their own perfectionism.
          </InfoText>

          <Title Icon={InvisiIcon} hideIcon>
            About Us
          </Title>
          <InfoText sx={{ mb: 0 }}>
            The people behind FrameShare are excited individuals with years of
            experience in art, art therapy, and content creation.
          </InfoText>
          <br />
          <ul
            sx={{
              listStyle: 'none',
              m: 0,
              p: 0,
              lineHeight: 1.1,
              pr: '0.5em',
              ml: '2em',
              mb: '0.5em'
            }}
          >
            <li>Founder: Will Wheeler</li>
            <li>Concept: Will Wheeler, Jacob Hoving and Ingo Valente</li>
            <li>Design: Jacob Hoving and Ingo Valente</li>
            <li>Development: Ingo Valente</li>
            <li>Content strategy: Sydney Combs</li>
            <li>
              Board of Advisors: Nancy Choe, Rick L. Garner, Reina Lombardi,
              Gretchen Miller, Erin Partridge, Jordan Potash, Lindsey Vance
            </li>
          </ul>
        </Box>

        <button
          sx={{
            position: 'fixed',
            top: '0.15em',
            right: '0.15em',
            appearance: 'none',
            bg: 'transparent',
            border: 'none',
            fontSize: '1.3em',
            lineHeight: 1.15,
            fontFamily: 'heading',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={handleClose}
        >
          Close
        </button>
      </Box>
    )
  )
}
